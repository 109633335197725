import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put, getAsync } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { formatOption } from "helpers/functions";
import { useLocation, useParams } from "react-router-dom";



const QualificationAdd = (props) => {
  document.title = "Qualification Master - VSP";

  const animatedComponents = makeAnimated();
  

  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const [categories, setCategories] = useState([])
  
  const [existingData, setExstinginData] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const { id } = useParams(); 
  const location = useLocation();

  const [formValues, setFormValues] = useState({
    name: '',
    category: null,

  })
  

  useEffect(() => {

    // Check if the 'editMode' parameter is present
    if (id) {
      setIsEditMode(true)

      async function fetchExsitingData() {

        get(url.GET_QUALIFICATION+id, {}, { headers: { credentials: 'include', Accept: "*/*" } }).then((response) => {

          console.log("FETCHED ", response)

          let responseData = response?.data || {};

          setExstinginData(responseData)

          let formValues = {
            name : responseData?.name || "",
            category : { label : responseData?.category_detail?.name || "", value : responseData?.category_id || null },
          }
          setFormValues(formValues);

          setLoading(false)

        }).catch((e) => {
          window.alert("Sorry!\n" + e)
        }).finally(() => {
          setLoading(false)
        });

      }
      fetchExsitingData();


      const data = location.state || {}; // Access your props here
      setExstinginData(data)
      // Enable edit mode logic here
      // console.log('Edit mode is enabled', id, data);
    }
  }, [id]);


  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: formValues,
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter qualification"),
      category: Yup.object().nullable().required("Please select category"),
    }),
    onSubmit: async (values) => {
      // Handle form submission logic here
      setLoading(true);
      let categoryDefaultValue = values.category
      values.category_id = values.category.value
      delete values.category
    
      console.log("VALS ",values);
      // let postRes = await post(url.CREATE_QUALIFICATION, values, { headers : {credentials: 'include', Accept : "*/*"}});
      // console.log("POSTED ",postRes)
      if(isEditMode) {
        values.id = id
        post(url.UPDATE_QUALIFICATION, values, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

         
          let message = response?.response || "";
          let responseData = response?.data || [];
          
          window.alert("Success!\n"+message)
          values.category = categoryDefaultValue
          window.history.go(-1);
          setLoading(false)
  
        }).catch((e) => {
          window.alert("Sorry!\n"+e)
        }).finally(() => {
          setLoading(false)
        });   
      } else {
        post(url.CREATE_QUALIFICATION, values, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

          console.log("RESPONSE ",response)
        
          let message = response?.response || "";
          let responseData = response?.data || [];
          
          console.log("POSTED ",responseData)
          window.alert("Success!\n"+message)

          values.name = ""
          values.category = null
          setLoading(false)

        }).catch((e) => {
          window.alert("Sorry!\n"+e)
        }).finally(() => {
          setLoading(false)
        });   

      }
    

      // setLoading(false);
    },
  });

  
  useEffect(() => {

    const breadcrumbItems = [
      { title: "VSP", link: "#" },
      { title: "Qualification", link: "#" },
      { title: isEditMode ? "Edit" : "Add", link: "#" },
    ]
    
    props.setBreadcrumbItems(isEditMode ? "Edit" : "Add", breadcrumbItems)
  })


  useEffect(() => {
    async function fetchData() {
      let response = await getAsync(url.GET_CATEGORY, {}, { headers : {credentials: 'include', Accept : "*/*"}});

      // console.log(formatOption(dropdowns?.courseType,"name","course_type_id"))
      // setCategories(formatOption(response?.data,"name","qualification_categories_id") || []);
      setCategories(formatOption(response?.data,"name","id") || []);
      

      console.log("RESPONSE ",response)
      setLoading(false)
     
      
    }
    fetchData();

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 


  const handleCategorySelect = (selectedOptions) => {
    validation.setFieldValue('category', selectedOptions);
  };



  return (
    <React.Fragment>
{ isLoading ? <Spinners  /> :
      <Row>
        <Col xl="12">
          <Card>
          <Form
                className="form-horizontal mt-4"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                  >
                <CardBody>
                  <h4 className="card-title">Qualification Add</h4>
                  <p className="card-title-desc"></p>
                
                    
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Qualification Name</Label>
                          <Input
                            name="name"
                            placeholder="Enter qualification name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ''}
                            invalid={validation.touched.name && validation.errors.name ? true : false}
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <div className="text-danger">{validation.errors.name}</div>
                          ) : null}
                        </div>
                      </Col>

                      <Col md="6">
                        <div className="mb-3">
                          <Label htmlFor="category">Category</Label>
                          <Select
                            value={validation.values.category}
                            isMulti={false}
                            onChange={handleCategorySelect}
                            options={categories}
                            id="category"
                            classNamePrefix="select2-selection"
                            isValid={!validation.errors.category && validation.touched.category}
                          />
                          {validation.touched.category && validation.errors.category ? (
                            <div className="text-danger">{validation.errors.category}</div>
                          ) : null}
                        </div>
                      </Col>


                    
                    </Row>

                </CardBody>
                <CardFooter>

                    <Row>
                      <Col md="6">
                          <Button color="primary" type="submit">
                            {isEditMode ? "Update" : "Add"}
                          </Button>
                      </Col>
                    </Row>
                </CardFooter>
              </Form>
          </Card>
        </Col>
      

      
      </Row>}
    

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(QualificationAdd);