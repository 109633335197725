import React, { useEffect } from "react"
import { Spinner } from "reactstrap";

const Spinners = ({ setLoading, color, className }) => {

    useEffect(() => {
        setTimeout(() => {
            if(setLoading) {
                setLoading(false)
            }
        }, 1000)
    }, [setLoading]);
    return (
        <React.Fragment>
            <Spinner color={ color || "primary"} className={className || 'position-absolute top-50 start-50'} />
        </React.Fragment>
    )
}

export default Spinners;