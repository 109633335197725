export const API_BASE_URL = "https://api.virtualskillshub.co.uk/";
export const API_URL = "https://api.virtualskillshub.co.uk/api";
// export const API_BASE_URL = "http://localhost:8080/";
// export const API_URL = "http://localhost:8080/api";

// export const API_URL = "https://api.vsp.xpertie.com/api";
// export const API_URL = "http://185.100.90.14:8080/api";


//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_PROD_JWT_LOGIN = "/auth/login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";
export const DELETE_MESSAGE = "/delete/message";

//TASKS
export const GET_TASKS = "/tasks";
export const DELETE_KANBAN = "/delete/tasks"
export const ADD_CARD_DATA = "/add/tasks"
export const UPDATE_CARD_DATA = "/update/tasks"



// CATEGORY MASTER
export const CREATE_CATEGORY = "/category/create";
export const GET_ALL_CATEGORY = "/category/get";
export const GET_ALL_MASTER_CATEGORY = "/category/get-master";
export const GET_ALL_COURSE_CATEGORY = "/category/get-course";
export const GET_CATEGORY = "/category/get/";
export const UPDATE_CATEGORY = "/category/update";
export const DELETE_COURSE_CATEGORY = "/category/delete-course-category";
export const DELETE_MASTER_CATEGORY = "/category/delete-master-category";


// COURSES
export const GET_COURSE_DROPDOWNS = "/course/get-dropdowns";
export const CREATE_COURSE = "/course/create";
export const GET_ALL_COURSES = "/course/get";
export const GET_COURSE = "/course/get/";
export const UPDATE_COURSE = "/course/update";
export const DELETE_COURSE = "/course/delete";

// Question Answers
export const GET_ALL_DEFAULT_QUESTIONS = "/qa/get-course-default";
export const GET_ALL_QUESTIONS = "/qa/get-course/";
export const CREATE_COURSE_ANSWERS = "/qa/create-course-answers";


// Qualifications
export const CREATE_QUALIFICATION = "/qualification/create";
export const GET_ALL_QUALIFICATIONS = "/qualification/get";
export const GET_QUALIFICATIONS_CATEGORIES = "/qualification/get-categories";
export const GET_QUALIFICATION = "/qualification/get/";
export const UPDATE_QUALIFICATION = "/qualification/update";
export const DELETE_QUALIFICATION = "/qualification/delete";

export const CREATE_QUALIFICATION_CATEGORY = "/qualification/category/create";
// export const GET_ALL_QUALIFICATIONS_CATEGORIES = "/qualification/category/get-categories";
export const GET_ALL_QUALIFICATIONS_CATEGORIES = "/qualification/category/get";
export const GET_QUALIFICATION_CATEGORY = "/qualification/category/get/";
export const UPDATE_QUALIFICATION_CATEGORY = "/qualification/category/update";
export const DELETE_QUALIFICATION_CATEGORY = "/qualification/category/delete";


//Language
export const GET_LANGUAGE = "/";

//Job Role
export const CREATE_JOB_ROLES = "/jobrole/create";
export const GET_JOB_ROLES_CATEGORIES = "/jobrole/get-categories";
export const GET_ALL_JOB_ROLES = "/jobrole/get";
export const GET_JOB_ROLE = "/jobrole/get/";
export const UPDATE_JOB_ROLE = "/jobrole/update";
export const DELETE_JOB_ROLE = "/jobrole/delete";
export const SAVE_JOB_ROLE_HIERARCHY = "/jobrole/save";
export const GET_JOB_ROLE_HIERARCHY = "/jobrole/get-hierarchy";

export const CREATE_JOB_ROLE_CATEGORY = "/jobrole/category/create";
// export const GET_JOB_ROLES_CATEGORIES = "/jobrole/get-categories";
export const GET_ALL_JOB_ROLE_CATEGORIES = "/jobrole/category/get";
export const GET_JOB_ROLE_CATEGORY = "/jobrole/category/get/";
export const UPDATE_JOB_ROLE_CATEGORY = "/jobrole/category/update";
export const DELETE_JOB_ROLE_CATEGORY = "/jobrole/category/delete";


//Skill
export const CREATE_SKILLS = "/skill/create";
export const GET_SKILLS_CATEGORIES = "/skill/get-categories";
export const GET_ALL_SKILLS = "/skill/get";
export const GET_SKILL = "/skill/get/";
export const UPDATE_SKILL = "/skill/update";
export const DELETE_SKILL = "/skill/delete";

//Skill Category
export const CREATE_SKILL_CATEGORY = "/skill/category/create";
// export const GET_ALL_SKILLS_CATEGORY = "/skill/get-categories";
export const GET_ALL_SKILLS_CATEGORIES = "/skill/category/get";
export const GET_SKILL_CATEGORY = "/skill/category/get/";
export const UPDATE_SKILL_CATEGORY = "/skill/category/update";
export const DELETE_SKILL_CATEGORY = "/skill/category/delete";



//Institue
export const CREATE_INSTITUTE = "/institute/create";
export const GET_INSTITUTES_CATEGORIES = "/institute/get-categories";
export const GET_ALL_INSTITUTES = "/institute/get";
export const GET_INSTITUTE = "/institute/get/";
export const UPDATE_INSTITUTE = "/institute/update";
export const DELETE_INSTITUTE = "/institute/delete";

// Institute Category
export const CREATE_INSTITUTE_CATEGORY = "/institute/category/create";
export const GET_ALL_INSTITUTES_CATEGORY = "/institute/get-categories";
// export const GET_ALL_INSTITUTES_CATEGORY = "/institute/get";
export const GET_INSTITUTE_CATEGORY = "/institute/category/get/";
export const UPDATE_INSTITUTE_CATEGORY = "/institute/category/update";
export const DELETE_INSTITUTE_CATEGORY = "/institute/category/delete";


// Availability
export const CREATE_AVAILABILITY = "/availability/create";
export const GET_AVAILABLITY_CATEGORIES = "/availability/get-categories";
export const GET_ALL_AVAILABILITIES = "/availability/get";
export const GET_AVAILABILITY = "/availability/get/";
export const UPDATE_AVAILABLITY = "/availability/update";
export const DELETE_AVAILABILITY = "/availability/delete";


// COURSE TYPE
export const CREATE_COURSE_TYPE = "/course-type/create";
export const GET_ALL_COURSE_TYPES = "/course-type/get";
export const GET_COURSE_TYPE = "/course-type/get/";
export const UPDATE_COURSE_TYPE = "/course-type/update";
export const DELETE_COURSE_TYPE = "/course-type/delete";

// COURSE ANSWER
export const CREATE_QA_ANSWER = "/qa/create-answer";
export const GET_ALL_QA_ANSWERS = "/qa/get-answers";
export const GET_QA_ANSWER = "/qa/get-answer/";
export const UPDATE_QA_ANSWER = "/qa/update-answer";
export const DELETE_QA_ANSWER = "/qa/delete-answer";

// COURSE QUESTION
export const CREATE_QA_QUESTION = "/qa/create-question";
export const GET_ALL_QA_QUESTIONS = "/qa/get-questions";
export const GET_QA_QUESTION = "/qa/get-question/";
export const UPDATE_QA_QUESTION = "/qa/update-question";
export const DELETE_QA_QUESTION = "/qa/delete-question";

// WORK LOAD TYPE
export const CREATE_WORK_LOAD_TYPE = "/work-load-type/create";
export const GET_ALL_WORK_LOAD_TYPES = "/work-load-type/get";
export const GET_WORK_LOAD_TYPE = "/work-load-type/get/";
export const UPDATE_WORK_LOAD_TYPE = "/work-load-type/update";
export const DELETE_WORK_LOAD_TYPE = "/work-load-type/delete";


// WORK LOAD TYPE
export const CREATE_BADGE = "/badge/create";
export const GET_ALL_BADGE = "/badge/get";
export const GET_BADGE = "/badge/get/";
export const UPDATE_BADGE = "/badge/update";
export const DELETE_BADGE = "/badge/delete";

//Mastdata
// export const GET_AVAILABILITY = "/";
// export const GET_BADGE  = "/";
// export const GET_COURSETYPE = "/";
// export const GET_INSTITUTE_CATEGORIES = "/";
// export const GET_JOB_ROLE_CATEGORIES = "/";
export const GET_MYANS_QUESTION = "/";
// export const GET_SKILLS_CATEGORIES = "/";
export const GET_MYSKILLS_QUESTION = "/";
// export const GET_WORKLOAD_TYPE = "/";