import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom";



const CourseQuestionsView = (props) => {
  document.title = "Questions - VSP";

  const animatedComponents = makeAnimated();
  const breadcrumbItems = [
    { title: "VSP", link: "#" },
    { title: "Question View", link: "#" },
    { title: "List", link: "#" },
  ]

  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)


  const [listData, setListData] = useState({
    columns: [
      {
        label: "Course Question Label",
        field: "course_question_label",
        sort: "asc",
        width: 150,
      },
      {
        label: "User Question Label",
        field: "user_question_label",
        sort: "asc",
        width: 150,
      },
      {
        label: "Answers",
        field: "default_answers",
        sort: "asc",
        width: 300,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 30,
      },
    ],
    rows: [],
  })
  

  
  useEffect(() => {
    
    props.setBreadcrumbItems('List', breadcrumbItems)
  })


  function deleteReq(id) {
    console.log("D ",id)
    setLoading(true)

    post(url.DELETE_QA_QUESTION, { id : id }, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

      // console.log("RESPONSE ",response)
    
      let message = response?.response || "";
      let responseData = response?.data || [];
      
      // console.log("POSTED ",listData)
      window.alert("Success!\n"+message)
      // window.location.reload()
      let existingData = listData;

      existingData.rows = renderRow(responseData);
      console.log("POSTED ",existingData)
      setListData(existingData);
      setLoading(false)

    }).catch((e) => {
      window.alert("Sorry!\n"+e)
      setLoading(false)
    }).finally(() => {
      
    });   

  }


  useEffect(() => {
    async function fetchData() {

      get(url.GET_ALL_QA_QUESTIONS, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

        // console.log("RESPONSE ",response)
      
        let responseData = response?.data || [];
        
        let existingData = listData;

        existingData.rows = renderRow(responseData);

        setListData(existingData);
        setLoading(false)

      }).catch((e) => {
        window.alert("Sorry!\n"+e)
        setLoading(false)
      }).finally(() => {
        
      });     
      
    }
    fetchData();

    // Perform your componentDidMount logic here
    // console.log('Component has mounted');

  }, []); 



  const renderRow = (responseData) => {
    let renderData = [];

    responseData.forEach((data,index) => {

      let item_row = {}

      let mainAnswers = "";
      data.answers.map((a,i) => {
        mainAnswers += a.answer+"\n"
        // mainAnswers.push(a.answer)
      })

      // console.log("ANSWERS ",mainAnswers)
      
      item_row.course_question_label = data?.course_question_label || "-";
      item_row.user_question_label = data?.user_question_label || "-";
      item_row.default_answers = data.answers.map((a,i) => {
        return(<div className="mb-2">
          <span className="question-table-tag">{a.answer}</span> <br />
        </div>)
      },"");
      item_row.actions =   <div className="d-flex flex-wrap gap-2">
          <Link state={data} to={`/qa-question-edit/${data?.question_id}`}><Button type="button"  color="primary" size="sm" className="waves-effect waves-light"><i className="fas fa-edit"></i></Button></Link>{" "}
          <Button type="button" onClick={() => deleteReq(data.question_id)} color="danger" size="sm" className="waves-effect waves-light"><i className="fas fa-trash"></i></Button>{" "}
      </div>;

      renderData.push(item_row);
    })

    return renderData;
  }


  return (
    <React.Fragment>
    
    { isLoading ? <Spinners /> :
      <Row>
        <Col xl="12">
          <Card>
                <CardHeader>
                <Row>
                  <Col md="12">
                    <CardTitle className="h4 mb-0">Questions List</CardTitle>
                  </Col>
                </Row>
                </CardHeader>
                <CardBody>
                
                    
                    <Row>
                      <Col className="mb-o" md="12">

                      <MDBDataTable noBottomColumns striped responsive bordered data={listData} />
                        
                      </Col>
                    </Row>

                </CardBody>
               
          </Card>
        </Col>
      

      
      </Row>
    }
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(CourseQuestionsView);