import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom";

// import { DndProvider } from 'react-dnd';
// import {HTML5Backend} from 'react-dnd-html5-backend';
// import MySortableTree from './HierarchyView';
// import Sortly, { ContextProvider } from 'react-sortly';
// import HierarchyView from "./HierarchyView";

import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
// import FileExplorerTheme from 'react-sortable-tree-theme-minimal';




const JobRoleHierarchyView = (props) => {
  document.title = "Job Role Master - VSP";

  const animatedComponents = makeAnimated();
  const breadcrumbItems = [
    { title: "VSP", link: "#" },
    { title: "Job Roles", link: "#" },
    { title: "Hierarchy", link: "#" },
  ]

  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState("")
  const [hierarchyID, setHierarchyID] = useState(null)

  // const [treeData, setTreeData] = useState([{ title: 'Category1', value: 1, children: [ { title: 'Category2',value: 2, children : [{ title : "Category3",value: 3 }] } ] }])
  const [treeData, setTreeData] = useState([])


  const [listData, setListData] = useState({
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 300,
      },
      {
        label: "Category",
        field: "category",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 30,
      },
    ],
    rows: [],
  })

  
  useEffect(() => {
    
    props.setBreadcrumbItems('Hierarchy', breadcrumbItems)
  })


  const saveHierarchy = () => {
    console.log("D ",hierarchyID)
    setLoading(true)

    post(url.SAVE_JOB_ROLE_HIERARCHY, { id : hierarchyID, hierarchy : treeData }, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

      // console.log("RESPONSE ",response)
    
      let message = response?.response || "";
      let responseData = response?.data || [];
      
      console.log("POSTED ",responseData)
      window.alert("Success!\n"+message)
      // window.location.reload()
      // let existingData = listData;

      // existingData.rows = renderRow(responseData);

      // setListData(existingData);
      setLoading(false)

    }).catch((e) => {
      window.alert("Sorry!\n"+e)
      setLoading(false)
    }).finally(() => {
      
    });   

  }


  useEffect(() => {
    async function fetchData() {


      get(url.GET_ALL_JOB_ROLES, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

        
        let responseData = response?.data || [];
        
        // let existingData = listData;

        // existingData.rows = renderRow(responseData);

        let hierarchyData = [];
        
        responseData.map((j,i) => {
            hierarchyData.push({ title: `${j.name}`, id : j.job_role_id, children: [] })
            // hierarchyData.push({ title: `(${j.job_role_id}) - ${j.name}`, id : j.job_role_id, children: [] })
        })

        setTreeData(hierarchyData);
  
        // setListData(existingData);
        console.log("RES JB ",hierarchyData)
        setLoading(false)

      }).catch((e) => {
        window.alert("Opps!\n"+e)
      }).finally(() => {
        // setLoading(false)
      });
    
       
        
    }
    async function fetchHierarchy() {

      get(url.GET_JOB_ROLE_HIERARCHY, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

        console.log("RESPONSE ",response)
      
        let responseData = response?.data || [];
        let defaultHierarchyID = response?.defaultHierarchyID || 1;
        
        console.log("D ",responseData)
        if(responseData.length == 0 || responseData[0].hierarchy == "[]") {
          setHierarchyID(defaultHierarchyID);
          fetchData();

        } else {
          setHierarchyID(responseData[0].id)
          setTreeData(JSON.parse(responseData[0].hierarchy))
        }

        setLoading(false)

      }).catch((e) => {
        window.alert("Sorry!\n"+e)
      }).finally(() => {
        setLoading(false)
      });     
      
    }
    
    fetchHierarchy();

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 


  // const saveHierarchy = () => {
  //   console.log(" Hierarchy ",treeData)
  // }


  return (
    <React.Fragment>
    
    { isLoading ? <Spinners /> :
      <Row>
        <Col xl="12">
          <Card>
                <CardHeader>
                <Row>
                  <Col md="10">
                    <CardTitle className="h4 mb-0">Job Roles Hierachy</CardTitle>
                  </Col>
                  {/* <Col md="6" style={{ textAlign : 'start' }}>
                      <Input
                        name="name"
                        placeholder="Enter job role name"
                        type="text"
                        onChange={(e) => setSearchQuery(e.target.value) }
                        value={searchQuery}
                      />
                  </Col> */}


                  <Col md="2" style={{ textAlign : 'end' }}>
                    <Button color="primary" onClick={() => saveHierarchy()} type="button">
                      Save
                    </Button>
                  </Col>
                </Row>
                </CardHeader>
                <CardBody>
                
                    
                    <Row>
                      <Col className="mb-o" md="11">
                        <Input
                          name="searchQuery"
                          placeholder="Find job role"
                          type="text"
                          onChange={(e) => setSearchQuery(e.target.value) }
                          value={searchQuery}
                        />
                      </Col>
                      <Col className="mb-o" md="1">
                          <Button color="danger" onClick={() => setSearchQuery("")} type="button">
                            Clear
                          </Button>
                      </Col>
                    </Row>
                    <div className="divider-1 mt-10 mb-10" style={{ marginTop : 20, marginBottom : 20 }}></div>
                    <Row>
                      <Col className="mb-o" md="12">

                      {/* <DndProvider backend={HTML5Backend}>
                        <ContextProvider>
                          <MySortableTree />
                        </ContextProvider>
                      </DndProvider> */}
                      <SortableTree
                        rowHeight={40}
                        slideRegionSize={20}
                        style={{ height : '65vh' }}
                        treeData={treeData}
                        onChange={data => setTreeData(data)}
                        theme={FileExplorerTheme}
                        searchQuery={searchQuery}
                      />

                      {/* <HierarchyView /> */}
                      {/* <MDBDataTable noBottomColumns striped responsive bordered data={listData} /> */}
                        
                      </Col>
                    </Row>

                </CardBody>
               
          </Card>
        </Col>
      

      
      </Row>
    }
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(JobRoleHierarchyView);