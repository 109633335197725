import { API_BASE_URL } from "./url_helper";

const formatOption = (arr,label,val) => { 
  if (!arr || arr.length === 0) {
    return []; // Return an empty array or handle the case as needed
  }
  
  return arr.map((originalObject) => {
    return {
      label: originalObject[label],
      value: originalObject[val],
    };
  });
}

const handleImageError = (event) => {
  let defaultSrc =  API_BASE_URL+"images/no-image.png"
  // Replace the image source with the default source
  event.target.src = defaultSrc;
};

const setDropdownValues = (form,existingValue,formKey,dataSource,label,value,isMulti) => { 

  // if(value == "courses_id") {
  //   console.log("F ",form,existingValue,formKey,dataSource,label,value,isMulti)
  // }

  
  // validation,"[1,2]","course_type",dropdowns?.courseTypes,"name","course_type_id"

  if (!dataSource || dataSource.length === 0) {
    // console.log("D ",dataSource,formKey)
    return []; // Return an empty array or handle the case as needed
  }
  // .setFieldValue('skills', selectedOptions); 


  if(isMulti) {
    let existingValues = JSON.parse(existingValue);

    // console.log("EXIS ",existingValue,formKey)
    let res = [];

    dataSource.map((originalObject) => {

      if (existingValues.includes(originalObject[value])) {
        res.push({
          label: originalObject[label],
          value: originalObject[value],
        })
      }

      
      
      form.setFieldValue(formKey, res); 

      return res;
      
    })

  } else {

    let existingValues = existingValue

    let res = {};

    // console.log("DD ",existingValues)

    // if (Array.isArray(existingValues)) {
    //   console.log('Variable is an array');
    // } else {
    //   console.log('Variable is not an array');
    // }

    dataSource.map((originalObject) => {

      if (existingValues == originalObject[value]) {
        res = {
          label: originalObject[label],
          value: originalObject[value],
        }

        form.setFieldValue(formKey, res);
        
      }

      return res;

      
    })

  }
  
  return dataSource.map((originalObject) => {
    return {
      label: originalObject[label],
      value: originalObject[value],
    };
  });
}

const parseMultiPickerValues = (arr,key) => { 
  if (!arr || arr.length === 0) {
    return []; // Return an empty array or handle the case as needed
  }

  let valuesArr = [];
  
  return arr.map((originalObject) => {
    return originalObject[key]
  });
}


const trimAndAddEllipsis = (inputString, maxLength) => {
  if (inputString.length > maxLength) {
    return inputString.substring(0, maxLength - 3) + '...';
  } else {
    return inputString;
  }
}
// export const getUserProfile = () => get(url.GET_USER_PROFILE)

const getCurrentUser = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    return obj
  } else {
    return null
  }
}

const calculateDiscount = (originalPrice,discountedPrice,addPercentIcon = false) => {
  const original = parseFloat(originalPrice);
  const discounted = parseFloat(discountedPrice);

  if (!isNaN(original) && !isNaN(discounted) && original > discounted) {
    const discount = ((original - discounted) / original) * 100;
    // setDiscountPercentage(discount.toFixed(2) + '%');
    if(addPercentIcon) {
      return discount.toFixed(2) + '%'
    } else {
      return discount.toFixed(2)
    }
  } else {
    return null;
    // setDiscountPercentage('Invalid input');
  }
};

export {
  formatOption,
  trimAndAddEllipsis,
  calculateDiscount,
  getCurrentUser,
  parseMultiPickerValues,
  setDropdownValues,
  handleImageError
}
