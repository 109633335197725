import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"



const LanguageAdd = (props) => {
  document.title = "Languages Master - VSP";

  const animatedComponents = makeAnimated();
  const breadcrumbItems = [
    { title: "VSP", link: "#" },
    { title: "Languages", link: "#" },
    { title: "Add", link: "#" },
  ]

  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)


  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ID: null,
      name: '',
     
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter language")
    }),
    onSubmit: async (values) => {
      // Handle form submission logic here
      
    
      console.log("VALS ",values);
      // let formPost = await post(url.CREATE_COURSE, values, { headers : {credentials: 'include', Accept : "*/*"}});
      // console.log("POSTED ",formPost)
    },
  });

  
  useEffect(() => {
    
    props.setBreadcrumbItems('Add', breadcrumbItems)
  })


  useEffect(() => {
    async function fetchData() {
    
     
      
    }
    fetchData();

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 



  return (
    <React.Fragment>
{ isLoading ? <Spinners setLoading={setLoading} /> :
      <Row>
        <Col xl="12">
          <Card>
              <Form
                    className="form-horizontal mt-4"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                <CardBody>
                  <h4 className="card-title">Languages Add</h4>
                  <p className="card-title-desc"></p>
                  
                    
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Language Name</Label>
                          <Input
                            name="name"
                            placeholder="Enter language name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ''}
                            invalid={validation.touched.name && validation.errors.name ? true : false}
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <div className="text-danger">{validation.errors.name}</div>
                          ) : null}
                        </div>
                      </Col>


                    
                    </Row>

                </CardBody>
                <CardFooter>

                    <Row>
                      <Col md="6">
                          <Button color="primary" type="submit">
                              Add
                          </Button>
                      </Col>
                    </Row>
                </CardFooter>

              </Form>
          </Card>
        </Col>
      

      
      </Row>}
    

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(LanguageAdd);