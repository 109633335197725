import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {

      const route = pathName.split('/')[1]; 

      // if(items[i].attributes?.otherpaths?.value.includes(route)) {
      //   console.log("ITEM ",route,items[i].attributes?.otherPaths?.value,items[i].attributes?.otherPaths?.value.includes(route))
      // }
      // console.log("PATH COMPARE ",pathName,items[i].pathname,items[i].attributes?.otherpaths?.value.includes(route))
      if (pathName === items[i].pathname || items[i].attributes?.otherpaths?.value.includes(route)) {
        console.log("CONDITION TRUE ",items[i])
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Main")} </li>

            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Courses Master")}</span>
              </Link>
              <ul className="sub-menu" >
                <li>
                  <Link to="/course-view" >{props.t("Courses List")}</Link>
                </li>
                <li>
                  <Link to="/course-add" otherpaths={["/course-edit","/course-add"]}>{props.t("Course Add")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Category Master")}</span>
              </Link>
              <ul className="sub-menu" >
                <li>
                  <Link to="/category-view">{props.t("Master Category View")}</Link>
                </li>
                <li>
                  <Link to="/category-add" otherpaths={["/category-edit","/category-add"]}>{props.t("Master Category Add")}</Link>
                </li>
                <li>
                  <Link to="/course-category-view">{props.t("Course Category View")}</Link>
                </li>
                <li>
                  <Link to="/course-category-add" otherpaths={["/course-category-edit","/course-category-add"]}>{props.t("Course Category Add")}</Link>
                </li>
              </ul>
            </li>


            


            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Languages Master")}</span>
              </Link>
              <ul className="sub-menu" >
                <li>
                  <Link to="/language-view">{props.t("Language View")}</Link>
                </li>
                <li>
                  <Link to="/language-add">{props.t("Language Add")}</Link>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <Link to="/language-add" className="waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Languages Add")}</span>
              </Link>
            </li> */}

            {/* <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span className="badge rounded-pill bg-primary float-end">2</span>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li> */}

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Institutes Master")}</span>
              </Link>
              <ul className="sub-menu" >
                <li>
                  <Link to="/institute-view">{props.t("Institutes List")}</Link>
                </li>
                <li>
                  <Link to="/institute-add" otherpaths={["/institute-edit","/institute-add"]}>{props.t("Institute Add")}</Link>
                </li>

                {/* <li>
                  <Link to="/institute-category-view">{props.t("Institute Category View")}</Link>
                </li>
                <li>
                  <Link to="/institute-category-add" otherpaths={["/institute-category-edit","/institute-category-add"]}>{props.t("Institute Category Add")}</Link>
                </li> */}
                
              </ul>
            </li>

            {/* <li>
              <Link to="/institute-add" className="waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Institute Add")}</span>
              </Link>
            </li> */}

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Qualifications Master")}</span>
              </Link>
              <ul className="sub-menu" >
                <li>
                  <Link to="/qualification-view">{props.t("Qualifications List")}</Link>
                </li>
                <li>
                  <Link to="/qualification-add" otherpaths={["/qualification-edit","/qualification-add"]} >{props.t("Qualification Add")}</Link>
                </li>

                {/* <li>
                  <Link to="/qualification-category-view">{props.t("Qualification Category View")}</Link>
                </li>
                <li>
                  <Link to="/qualification-category-add" otherpaths={["/qualification-category-edit","/qualification-category-add"]}>{props.t("Qualification Category Add")}</Link>
                </li> */}
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Job Role Master")}</span>
              </Link>
              <ul className="sub-menu" >
                <li>
                  <Link to="/job-role-view">{props.t("Job Roles List")}</Link>
                </li>
                <li>
                  <Link to="/job-role-hierarchy-view">{props.t("Job Roles Hierachy")}</Link>
                </li>
                <li>
                  <Link to="/job-role-add" otherpaths={["/job-role-edit","/job-role-add"]}>{props.t("Job Role Add")}</Link>
                </li>
                {/* <li>
                  <Link to="/job-role-category-view">{props.t("Job Role Category")}</Link>
                </li> */}

                {/* <li>
                  <Link to="/job-role-category-view">{props.t("Job Roles Category View")}</Link>
                </li>
                <li>
                  <Link to="/job-role-category-add" otherpaths={["/job-role-category-edit","/job-role-category-add"]}>{props.t("Job Roles Category Add")}</Link>
                </li> */}
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Skills Master")}</span>
              </Link>
              <ul className="sub-menu" >
                <li>
                  <Link to="/skill-view">{props.t("Skills List")}</Link>
                </li>
                <li>
                  <Link to="/skill-add" otherpaths={["/skill-edit","/skill-add"]}>{props.t("Skill Add")}</Link>
                </li>

                {/* <li>
                  <Link to="/skill-category-view">{props.t("Skill Category View")}</Link>
                </li>
                <li>
                  <Link to="/skill-category-add" otherpaths={["/skill-category-edit","/skill-category-add"]}>{props.t("Skill Category Add")}</Link>
                </li> */}
              </ul>
            </li>

            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Availability Master")}</span>
              </Link>
              <ul className="sub-menu" >
                <li>
                  <Link to="/availability-view">{props.t("Availability List")}</Link>
                </li>
                <li>
                  <Link to="/availability-add" otherpaths={["/availability-edit","/availability-add"]}>{props.t("Availability Add")}</Link>
                </li>
              </ul>
            </li> */}

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Course Type Master")}</span>
              </Link>
              <ul className="sub-menu" >
                <li>
                  <Link to="/course-type-view">{props.t("Course Type List")}</Link>
                </li>
                <li>
                  <Link to="/course-type-add" otherpaths={["/course-type-edit","/course-type-add"]}>{props.t("Course Type Add")}</Link>
                </li>
              </ul>
            </li>


            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("WorkLoad Type Master")}</span>
              </Link>
              <ul className="sub-menu" >
                <li>
                  <Link to="/work-load-type-view">{props.t("Work Load Type List")}</Link>
                </li>
                <li>
                  <Link to="/work-load-type-add" otherpaths={["/work-load-type-edit","/work-load-type-add"]}>{props.t("Work Load Type Add")}</Link>
                </li>
              </ul>
            </li> */}
            
            
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Badge Master")}</span>
              </Link>
              <ul className="sub-menu" >
                <li>
                  <Link to="/badge-view">{props.t("Badge List")}</Link>
                </li>
                <li>
                  <Link to="/badge-add" otherpaths={["/badge-edit","/badge-add"]}>{props.t("Badge Add")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("QA Master")}</span>
              </Link>
              <ul className="sub-menu" >
                <li>
                  <Link to="/qa-answers-view">{props.t("Answers List")}</Link>
                </li>
                <li>
                  <Link to="/qa-answer-add" otherpaths={["/qa-answer-edit","/qa-answer-add"]}>{props.t("Answer Add")}</Link>
                </li>
                <li>
                  <Link to="/qa-questions-view">{props.t("Questions List")}</Link>
                </li>
                <li>
                  <Link to="/qa-question-add" otherpaths={["/qa-question-edit","/qa-question-add"]}>{props.t("Question Add")}</Link>
                </li>
              </ul>
            </li>

            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Other Informations")}</span>
              </Link>
              <ul className="sub-menu" >
                /* <li>
                  <Link to="/availability-view">{props.t("Availability")}</Link>
                </li> *
                /* <li>
                  <Link to="/badge-view">{props.t("Badge")}</Link>
                </li> *
                /* <li>
                  <Link to="/coursetype-view">{props.t("Course Type")}</Link>
                </li> *
                
                <li>
                  <Link to="/myanswers-questions-view">{props.t("Myanswers Question")}</Link>
                </li>
                <li>
                  <Link to="/myskills-questions-view">{props.t("Myskills Question")}</Link>
                </li>
                {/* <li>
                  <Link to="/skills-category-view">{props.t("Skills Category")}</Link>
                </li> */
                /* <li>
                  <Link to="/workload-type-view">{props.t("Workload Type")}</Link>
                </li> *
              </ul>
            </li> */}

            {/* <li>
              <Link to="/qualification-add" className="waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Qualification Add")}</span>
              </Link>
            </li> */}

            {/* <li>
              <Link to="/calendar" className=" waves-effect">
                <i className="mdi mdi-calendar-check"></i>
                <span>{props.t("Calendar")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-email-outline"></i>
                <span>{props.t("Email")}</span>
              </Link>
              <ul className="sub-menu" >
                <li>
                  <Link to="/email-inbox">{props.t("Inbox")}</Link>
                </li>
                <li>
                  <Link to="/email-read">{props.t("Email Read")} </Link>
                </li>
                <li>
                  <Link to="/email-compose">{props.t("Email Compose")} </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/chat" className=" waves-effect">
                <i className="mdi mdi-chat-processing-outline"></i>
                <span className="badge rounded-pill bg-danger float-end">Hot</span>
                <span>Chat</span>
              </Link>
            </li>

            <li>
              <Link to="/kanbanboard" className=" waves-effect">
                <i className="mdi mdi-billboard"></i>
                <span className="badge rounded-pill bg-success float-end">New</span>
                <span>Kanban Board</span>
              </Link>
            </li>

            <li className="menu-title">{props.t("Components")}</li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-buffer"></i>
                <span>{props.t("UI Elements")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ui-alerts">{props.t("Alerts")}</Link>
                </li>
                <li>
                  <Link to="/ui-buttons">{props.t("Buttons")}</Link>
                </li>
                <li>
                  <Link to="/ui-badge">{props.t("Badge")}</Link>
                </li>
                <li>
                  <Link to="/ui-cards">{props.t("Cards")}</Link>
                </li>
                <li>
                  <Link to="/ui-carousel">{props.t("Carousel")}</Link>
                </li>
                <li>
                  <Link to="/ui-dropdowns">{props.t("Dropdowns")}</Link>
                </li>
                <li>
                  <Link to="/ui-utilities">Utilities<span className="badge rounded-pill bg-success float-end">New</span></Link>
                </li>
                <li>
                  <Link to="/ui-grid">{props.t("Grid")}</Link>
                </li>
                <li>
                  <Link to="/ui-images">{props.t("Images")}</Link>
                </li>
                <li>
                  <Link to="/ui-lightbox">{props.t("Lightbox")}</Link>
                </li>
                <li>
                  <Link to="/ui-modals">{props.t("Modals")}</Link>
                </li>
                <li>
                  <Link to="/ui-colors">Colors<span className="badge rounded-pill bg-warning float-end">New</span></Link>
                </li>
                <li>
                  <Link to="/ui-offcanvas">Offcanvas<span className="badge rounded-pill bg-warning float-end">New</span></Link>
                </li>
                <li>
                  <Link to="/ui-pagination">{props.t("Pagination")}</Link>
                </li>
                <li>
                  <Link to="/ui-popover-tooltip">{props.t("Popover & Tooltips")}</Link>
                </li>
                <li>
                  <Link to="/ui-rangeslider">{props.t("Range Slider")}</Link>
                </li>
                <li>
                  <Link to="/ui-session-timeout">{props.t("Session Timeout")}</Link>
                </li>
                <li>
                  <Link to="/ui-progressbars">{props.t("Progress Bars")}</Link>
                </li>
                <li>
                  <Link to="/ui-tabs-accordions">{props.t("Tabs & Accordions")}</Link>
                </li>
                <li>
                  <Link to="/ui-typography">{props.t("Typography")}</Link>
                </li>
                <li>
                  <Link to="/ui-video">{props.t("Video")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="waves-effect">
                <i className="mdi mdi-clipboard-outline"></i>
                <span className="badge rounded-pill bg-success float-end">6</span>
                <span>{props.t("Forms")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/form-elements">{props.t("Form Elements")}</Link>
                </li>
                <li>
                  <Link to="/form-validation">{props.t("Form Validation")}</Link>
                </li>
                <li>
                  <Link to="/form-advanced">{props.t("Form Advanced")}</Link>
                </li>
                <li>
                  <Link to="/form-editors">{props.t("Form Editors")}</Link>
                </li>
                <li>
                  <Link to="/form-uploads">{props.t("Form File Upload")} </Link>
                </li>
                <li>
                  <Link to="/form-xeditable">{props.t("Form Xeditable")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-chart-line"></i>
                <span>{props.t("Charts")}</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/apex-charts">
                    {props.t("Apex charts")}
                  </Link>
                </li>

                <li>
                  <Link to="/charts-chartjs" className="dropdown-item">
                    {props.t("Chartjs Chart")}
                  </Link>
                </li>
                <li>
                  <Link to="/sparkline-charts" className="dropdown-item">
                    {props.t("Sparkline Chart")}
                  </Link>
                </li>
                <li>
                  <Link to="/charts-c3" className="dropdown-item">
                    {props.t("C3 Chart")}
                  </Link>
                </li>
                <li>
                  <Link to="/charts-knob" className="dropdown-item">
                    {props.t("Jquery Knob Chart")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-format-list-bulleted-type"></i>
                <span>{props.t("Tables")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/tables-basic">{props.t("Basic Tables")}</Link>
                </li>
                <li>
                  <Link to="/tables-datatable">{props.t("Data Tables")}</Link>
                </li>
                <li>
                  <Link to="/tables-responsive">
                    {props.t("Responsive Table")}
                  </Link>
                </li>
                <li>
                  <Link to="/tables-editable">{props.t("Editable Table")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-album"></i>
                <span>{props.t("Icons")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/icons-materialdesign">
                    {props.t("Material Design")}
                  </Link>
                </li>
                <li>
                  <Link to="/icons-ion">{props.t("Ion Icons")}</Link>
                </li>
                <li>
                  <Link to="/icons-fontawesome">{props.t("Font Awesome")}</Link>
                </li>
                <li>
                  <Link to="/icons-themify">{props.t("Themify Icons")}</Link>
                </li>
                <li>
                  <Link to="/icons-dripicons">{props.t("Dripicons")}</Link>
                </li>
                <li>
                  <Link to="/icons-typicons">{props.t("Typicons Icons")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="waves-effect">
                <span className="badge rounded-pill bg-danger float-end">2</span>
                <i className="mdi mdi-google-maps"></i>
                <span>{props.t("Maps")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/maps-google">{props.t("Google Maps")}</Link>
                </li>
                <li>
                  <Link to="/maps-vector">{props.t("Vector Maps")}</Link>
                </li>

              </ul>
            </li>

            <li className="menu-title">Extras</li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-account-box"></i>
                <span>{props.t("Authentication")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pages-login">{props.t("Login")}</Link>
                </li>
                <li>
                  <Link to="/pages-register">{props.t("Register")}</Link>
                </li>
                <li>
                  <Link to="/page-recoverpw">
                    {props.t("Recover Password")}
                  </Link>
                </li>
                <li>
                  <Link to="/auth-lock-screen">{props.t("Lock Screen")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-text-box-multiple-outline"></i>
                <span>{props.t("Extra Pages")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pages-timeline">{props.t("Timeline")}</Link>
                </li>
                <li>
                  <Link to="/pages-invoice">{props.t("Invoice")}</Link>
                </li>
                <li>
                  <Link to="/pages-directory">{props.t("Directory")}</Link>
                </li>
                <li>
                  <Link to="/pages-blank">{props.t("Blank Page")}</Link>
                </li>
                <li>
                  <Link to="/pages-404">{props.t("Error 404")}</Link>
                </li>
                <li>
                  <Link to="/pages-500">{props.t("Error 500")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-share-variant"></i>
                <span>{props.t("Multi Level")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Level 1.1")}</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Level 1.2")}
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/#">{props.t("Level 2.1")}</Link>
                    </li>
                    <li>
                      <Link to="/#">{props.t("Level 2.2")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
