import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, getAsync, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { formatOption } from "helpers/functions";
import { useLocation, useParams } from "react-router-dom";



const CourseQuestionAdd = (props) => {
  document.title = "Questions Master - VSP";

  const animatedComponents = makeAnimated();

  const INITIAL_VALUES = {
    course_question_label: '',
    user_question_label: '',
    default_answers: [],
  }
 

  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)

  const [existingData, setExstinginData] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const { id } = useParams(); 
  const location = useLocation();

  const [allAnswers, setAllAnswers] = useState([])
  const [selectedAnswers, setSelectedAnswers] = useState([])

  const [formValues, setFormValues] = useState(INITIAL_VALUES)
  

  useEffect(() => {

    // Check if the 'editMode' parameter is present
    if (id) {
      setLoading(true)
      setIsEditMode(true)

      async function fetchExsitingData() {

        get(url.GET_QA_QUESTION+id, {}, { headers: { credentials: 'include', Accept: "*/*" } }).then((response) => {

          console.log("FETCHED ", response)

          let responseData = response?.data || {};

          setExstinginData(responseData)

          // setAllAnswers(formatOption(responseData?.answers,"answer","answer_id") || []);

          // validation.setFieldValue("default_answers",formatOption(responseData?.answers,"answer","answer_id") || [])

          let formValues = {
            course_question_label : responseData?.course_question_label || "",
            user_question_label : responseData?.user_question_label || "",
            default_answers : formatOption(responseData?.answers,"answer","answer_id") || [],
          }
          setFormValues(formValues);

          setLoading(false)

        }).catch((e) => {
          window.alert("Sorry!\n" + e)
        }).finally(() => {
          // setLoading(false)
        });

      }
      fetchExsitingData();


      const data = location.state || {}; // Access your props here
      setExstinginData(data)
      // Enable edit mode logic here
      // console.log('Edit mode is enabled', id, data);
    } else {
      setLoading(false)
    }
  }, [id]);




  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: formValues,
    validationSchema: Yup.object({
      course_question_label: Yup.string().required("Please enter course question label"),
      user_question_label: Yup.string().required("Please enter user questions label"),
      default_answers: Yup.array().min(2, "Please Select at least two answers"),
    }),
    onSubmit: async (values) => {
      // Handle form submission logic here
      
      setLoading(true);
    
      let defautlAnswers = []

      values.default_answers.map((a,i) => {
        defautlAnswers.push(a.value)
      })

      values.default_answers = defautlAnswers;

      // console.log("VALS ",isEditMode,values);

      // return
      if(isEditMode) {
        values.id = id
        post(url.UPDATE_QA_QUESTION, values, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

         
          let message = response?.response || "";
          let responseData = response?.data || [];
          
          window.alert("Success!\n"+message)
          window.history.go(-1);
          setFormValues(INITIAL_VALUES)
          setLoading(false)
  
        }).catch((e) => {
          window.alert("Sorry!\n"+e)
        }).finally(() => {
          setLoading(false)
        });   
        
      } else {
        post(url.CREATE_QA_QUESTION, values, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

          console.log("RESPONSE ",response)
        
          let message = response?.response || "";
          let responseData = response?.data || [];
          
          console.log("POSTED ",responseData)
          window.alert("Success!\n"+message)
          
          // window.location.reload()
          setFormValues(INITIAL_VALUES)
          validation.resetForm(INITIAL_VALUES)
          setLoading(false)
          

        }).catch((e) => {
          window.alert("Sorry!\n"+e)
        }).finally(() => {
          setLoading(false)
        });   

      }
     
    },
  });

  
  useEffect(() => {
    const breadcrumbItems = [
      { title: "VSP", link: "#" },
      { title: "Questions", link: "#" },
      { title: isEditMode ? "Edit" : "Add", link: "#" },
    ]
    props.setBreadcrumbItems(isEditMode ? "Edit" : "Add", breadcrumbItems)
  })


  useEffect(() => {
    async function fetchData() {  
      let { data } = await get(url.GET_ALL_QA_ANSWERS, {}, { headers : {credentials: 'include', Accept : "*/*"}});
      // console.log("ANSWERs",data)
      setAllAnswers(formatOption(data,"answer","answer_id") || []);
      // setLoading(false)
    }
    fetchData();

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 


  const handleQuestionAsnwersListSelect = (selectedOptions) => {
    validation.setFieldValue('default_answers', selectedOptions);
    setSelectedAnswers(selectedOptions)
  };


  return (
    <React.Fragment>
        { isLoading ? <Spinners  />   :
      <Row>
        <Col xl="12">
          <Card>
          <Form
                className="form-horizontal mt-4"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                >
                <CardBody>
                  <h4 className="card-title">Question Add</h4>
                  <p className="card-title-desc"></p>
                
                    
                 
                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom01">Course Question Label</Label>
                        <Input
                          name="course_question_label"
                          placeholder="Enter question course label"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.course_question_label || ''}
                          invalid={validation.touched.course_question_label && validation.errors.course_question_label ? true : false}
                        />
                        {validation.touched.course_question_label && validation.errors.course_question_label ? (
                          <div className="text-danger">{validation.errors.course_question_label}</div>
                        ) : null}
                      </div>
                    </Col>
                    
                    <Col md="6">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom01">User Question Label</Label>
                        <Input
                          name="user_question_label"
                          placeholder="Enter question user label"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.user_question_label || ''}
                          invalid={validation.touched.user_question_label && validation.errors.user_question_label ? true : false}
                        />
                        {validation.touched.user_question_label && validation.errors.user_question_label ? (
                          <div className="text-danger">{validation.errors.user_question_label}</div>
                        ) : null}
                      </div>
                    </Col>
                    
                  
                  </Row>

                  <Row>
                    <Col md="12">
                      <div className="mb-3">
                        <Label htmlFor="default_answers">Question Answers List</Label>
                        <Select
                          placeholder={"Answers List"}
                          value={validation.values.default_answers}
                          isMulti={true}
                          onChange={handleQuestionAsnwersListSelect}
                          options={allAnswers}
                          id="default_answers"
                          classNamePrefix="select2-selection"
                          isValid={!validation.errors.default_answers && validation.touched.default_answers}
                        />
                        {validation.touched.default_answers && validation.errors.default_answers ? (
                          <div className="text-danger">{validation.errors.default_answers}</div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                </CardBody>
                <CardFooter>

                    <Row>
                      <Col md="6">
                          <Button color="primary" type="submit">
                              {isEditMode ? "Update" : "Add"}
                          </Button>
                      </Col>
                    </Row>
                </CardFooter>

            </Form> 
          </Card>
        </Col>
      

      
      </Row>}
    

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(CourseQuestionAdd);